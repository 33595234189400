<template>
    <market-module-component/>
</template>

<script>
    import MarketModuleComponent from "@/components/admin/MarketModuleComponent";

    export default {
        name: "MarketModule",
        title: "Acceso a Mercado de Capital | Private",
        components: { MarketModuleComponent }
    }
</script>

<style scoped>

</style>